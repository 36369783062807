




import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'layoutFooter',
})
export default class Footer extends Vue {}
